.print-format #header-html {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.print-format .letter-head {
  height: 20mm;
}

.print-format .contact-row {
  position: relative;
  right: 20mm;
  width: 170mm;
  height: 45mm;
  top: 15mm;
}

.print-format #contact,
.print-format #contact > table {
  /** Set Font Size for this Row in only one Place */
  /** Fixes Html preview problems with tables */
  font-size: 8pt;
}

.print-format .sender {
  position: relative;
  /* font-size: 75%; */
  /* height: 17.7mm */
  font-size: 6pt !important;
  text-align: right;
  padding-left: 2pt;
  padding-bottom: 1px;
  border-bottom: solid black 1px;
  margin-bottom: 1px;
  white-space: nowrap;
  display: inline-block;
}

.print-format #address {
  float: right;
  width: 95mm;
  padding-right: 5mm;
  overflow: hidden;
  height: 45mm;
}

.print-format #address-border {
  display: block;
  width: 85mm;
  height: 40mm;
  border: 1px solid transparent;
  position: absolute;
  right: 5mm;
  top: 0;
}

.print-format #contact {
  float: left;
  width: 75mm;
  margin-top: -10mm;
}

.print-format #contact > table {
  width: 100%;
}

.print-format #contact td {
  padding: 1.5px !important;
}

.print-format #subject {
  margin-bottom: 2em;
  height: 5mm;
  font-weight: bold;
}

.print-format .din-mark {
  height: 1pt;
  background-color: black;
  position: absolute;
}

.print-format #faltmarke-1 {
  top: 85mm;
  width: 12mm;
}

.print-format #lochmarke {
  top: 128.5mm;
  width: 7mm;
}

.print-format #faltmarke-2 {
  top: 190mm;
  width: 12mm;
}

.clear {
  clear: both;
}

.print-format .contact-row {
  position: relative;
  right: 20mm;
  width: 170mm;
  height: 45mm;
}

.print-format #contact,
.print-format #contact > table {
  /** Set Font Size for this Row in only one Place */
  /** Fixes Html preview problems with tables */
  font-size: 8.5pt;
}

.print-format #text {
  margin-top: 8.46mm;
  margin-right: 25mm;
  margin-left: 20mm;
}

.print-format .text-right {
  text-align: left;
}

.print-format .text-small {
  font-size: 10pt !important;
}

.print-format .black-border {
  border-top: solid #000 1px;
  border-bottom: solid #000 1px;
}

.print-format .w-100 {
  width: 100%;
}

.print-format table {
  page-break-inside: auto;
  table-layout: fixed;
}

.print-format table tr {
  page-break-inside: avoid;
  page-break-after: auto;
}

.print-format table thead {
  display: table-header-group;
}

.print-format table tfoot {
  display: table-row-group;
}

.print-format table tr.description td {
  padding-top: 0mm !important;
}

.print-format .epilogue {
  text-align: justify;
}

.print-format .epilogue .salutation {
  page-break-inside: avoid;
}

.print-format .ql-editor {
  line-height: unset;
  font-family: unset;
  overflow: hidden;
}

.print-format .ql-editor p {
  margin: unset;
}

.unterschrift {
  position: relative;
  height: 100px;
  width: 300px;
  overflow: hidden;
}

.unterschrift_text {
  position: relative;
  margin-top: 30px;
  z-index: 101;
  width: 300px;
  display: inline-block;
}

.unterschrift_bild img {
  margin: 0 0;
  margin-top: 0px;
  position: absolute;
  z-index: 100;
  right: 0px;
  top: -12mm;
  width: 300px;
  height: 150px;
  opacity: 1;
  /* border: 1px solid red; */
}

.underline {
  border-bottom: solid black 1px !important;
}

.overline {
  border-top: solid black 1px !important;
}

.center {
  text-align: center !important;
}

.justify {
  text-align: justify !important;
}

.right {
  text-align: left !important;
}

.left {
  text-align: right !important;
}